@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: menu-bubble-out infinite 20s linear;
  }
}

@font-face {
  font-family: 'raleway-bold';
  src: url('./res/fonts/Raleway-Bold.ttf');
}

@font-face {
  font-family: 'raleway-regular';
  src: url('./res/fonts/Raleway-Regular.ttf');
}

@font-face {
  font-family: 'raleway-medium';
  src: url('./res/fonts/Raleway-Medium.ttf');
}

@font-face {
  font-family: 'babylonica';
  src: url('./res/fonts/Babylonica-Regular.ttf');
}

@font-face {
  font-family: 'moirai';
  src: url('./res/fonts/MoiraiOne-Regular.ttf');
}

.Global {
  background-color: #131534;
  color: white
}

.Global-light {
  background-color: white;
  color: darkslategray
}

.Cover-Box {
  flex: auto;
  background-color: #1ebb65; /*#1ebb65*/
  width: 100%;
  height: 32em;
}

.Cover-Box-Dark {
  flex: auto;
  background-color: #131534; /*#131534*/
  width: 100%;
  height: 32em;
}

.App-header-bg {
  flex: auto;
  display: flex;
  align-items: left;
  background-color: #1ebb65;
  height: 64px;
  opacity: 1;
  justify-content: space-between;
}

.App-header-bg-dark {
  flex: auto;
  display: flex;
  align-items: left;
  background-color: none;
  height: 64px;
  opacity: 1;
  justify-content: space-between;
}

.App-header {
  flex-direction: row;
  align-items: space-between;
  display: flex;
}

.App-header-link-bg {
    flex-direction: row;
    justify-content: space-between;
    display: flex;
    font-size: calc(5px + 1vmin);
    font-family: 'raleway-medium';
}

.App-header-link {
    display: block;
    color: white;
    line-height: 64px;
    padding: 0 24px;
    text-decoration: none;
}


.Normal {
    display: block;
    color: white;
    line-height: 64px;
    padding: 0 24px;
    text-decoration: none;
}

.Bubble-Out {
  /* background-color: white; */
  color: #05386b;
  /* transform: scale3d(1.2, 1.2, 1.2); */
  text-decoration: none;
  text-align: center;
  line-height: 64px;
  padding: 0 24px;
}

.Bubble-Out-Dark {
  /* background-color: white; */
  color: #0b76e1;
  /* transform: scale3d(1.2, 1.2, 1.2); */
  text-decoration: none;
  text-align: center;
  line-height: 64px;
  padding: 0 24px;
}

.Bubble-Out-Sidebar {
  /* background-color: white; */
  color: #05386b;
  text-decoration: none;
  text-align: center;
  line-height: 64px;
  padding: 0 24px;
}

a.App-header-link:hover {
  /* background-color: white; */
  color: #05386b;
  /* animation: menu-bubble-out 1s forwards cubic-bezier(0.19, 1, 0.22, 1); */
}

a.App-header-link-dark:hover {
  /* background-color: white; */
  color: #05386b;
  /* animation: menu-bubble-out 1s forwards cubic-bezier(0.19, 1, 0.22, 1); */
}

a.App-header-link:not(:hover)  {
  transition-duration: 0.3s;
  /* animation: menu-bubble-in 1s cubic-bezier(0.19, 1, 0.22, 1); */
}

.Header-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-family: 'Raleway-Bold';
  font-weight: bold;
  color: #05386b;
}

.Header-title-dark {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-family: 'Raleway-Bold';
  font-weight: bold;
  color: #0a76e2;
}

.H3 {
  font-size: 15px;
  font-weight: bold;
}

.H2 {
  font-size: 18px;
  font-weight: bold;
}

h2 {
  font-size: 32px;
  font-weight: 200;
  letter-spacing: 5px;
  line-height: 1;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.neumorphic-box {
  box-shadow: 20px 20px 50px #00d2c6,   
  -30px -30px 60px #00ffff;
}


.Page-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  color: white;
}

.Compact-Page-Size {
  width: 50%;
}

.FullSize {
  width: 100%;
}

.Centered-Text {
  text-align: center;
}

.lightText {
  color: white;
}

.darkText {
  color: black;
}

.Page-Margin {
  margin: 30px;
}

.Centered-Items-Horizontally {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Scrollable {
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
}

.AwesomeBG {
  background: linear-gradient(90deg, #b07640, #79a631);
  color: white;
}

.Project-Transform {
  animation: 1s transform 0.5s backwards ease-in-out alternate-reverse infinite;
  transform: rotateX(40deg) rotateZ(50deg) translateZ(-50px);
  z-index: 1;
  /* box-shadow: 11px 11px 21px #4aaf77,
  -11px -11px 21px #6effb3; ; */
}

.Project-Transform:nth-child(odd) {
  animation: 1s transform backwards ease-in-out alternate-reverse infinite;
  transform: rotateX(40deg) rotateZ(50deg) translateX(50px);
  z-index: 5;
  /* box-shadow: 11px 11px 21px #4aaf77,
  -11px -11px 21px #6effb3; ; */
}

@keyframes transform {
  from {
    top: 20px;
    position: relative;
  }
  to {
    top: 50px;
    position: relative;
  }
}

@keyframes transform-reverse {
  from {
    margin-top: 50px;
  }
  to {
    margin-top: 20px;
  }
}


.Centered-Items-Vertically {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.CenterAlign {
  align-items: center;
}

.BottomAlign {
  align-items: baseline;
}

.CenterJustify {
  justify-content: center;
}

.BottomJustify {
  justify-content: flex-end;
}

.CenterJustifyItems {
  justify-items: center;
}

.EndToEnd{
  display: flex;
  justify-content: space-between;
}

.DisplayFlex {
  display: flex;
}

.Row {
  flex-direction: row;
}

.Col {
  flex-direction: column;
}

.Col-Rev {
  flex-direction: column-reverse;
}

.Row-Rev {
  flex-direction: row-reverse;
}

.Wrap {
  flex-wrap: wrap;
}

.Leading-Col {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
}

.Leading-X {
  align-items: flex-start;
}

.Trailing-Col {
  display: flex;
  flex-direction: column;
  justify-items: flex-end;
}

.Leading-Row {
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
}

.Trailing-Row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.quicklinks {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.quicklinks div {
  justify-content: space-around;
  align-items: center;
  width: 50%;
  margin-bottom: 30px;
}

.Width70Percent {
  width: 70%;
}


.Page-container-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 60%;
}

.centered {
  align-self: center;
}

.thick-bordered {
  border-color: #05386b;
  border-style: inset;
  border-width: 1;
  border-radius: 10px;
}

.bordered {
  border-width: 1px;
  border-style: inset;
  border-color: black;
  border-radius: 10px;
}

.standard-padding {
  padding: 10px;
}

.standard-padding-vertical {
  padding-top: 10px;
  padding-bottom: 10px;
}

.standard-margin {
  margin: 20px;
}

.standard-margin-vertical {
  margin-top: 20px;
  margin-bottom: 20px;
}

.standard-margin-horizontal {
  margin-left: 10px;
  margin-right: 10px;
}

.standard-margin-left {
  margin-left: 20px;
}

.standard-margin-right {
  margin-right: 20px;
}

.standard-margin-top {
  margin-top: 20px;
}

.standard-margin-bottom {
  margin-bottom: 20px;
}

.large-margin-bottom {
  margin-bottom: 40px;
}

.Card {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 1vmin);
  font-family: 'highlandLight';
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
}

.Card:hover {
  transform: scale(1.5, 1.5);
  z-index: 10;
  transition: ease-out;
  transition-duration: 0.3s;
}

.Card:not(:hover)  {
  transition: ease-out;
  transition-duration: 0.3s;
}

.RatingChip {
  display: flex;
  border-width: 1px;
  border-style: inset;
  border-color: black;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin-left: 5px;
  margin-bottom: 5px;
}

.RatingChipFilled {
  background-color: #5CDB95;
}

.ChipCard {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  justify-items: center;
}

.SkillChip {
  display: flex;
  background-color: rgb(103, 231, 150);
  color: #000;
  padding: 10px;
  border-width: 1px;
  border-style: inset;
  border-color: black;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin: 5px;
  font-weight: bold;
}

.box-shadow {
  border-radius: 17px;
  background: #5CDB95;
  box-shadow:  11px 11px 21px #4aaf77,
             -11px -11px 21px #6effb3;
}

#icon {
  padding: 10px;
  color: #05386b;
}

#light-icon {
  padding: 10px;
  color: white;
  width: 40px;
  height: 40px;
}

.menu-icon{
  padding: 10px;
  width: 20px;
}

.SideBar-bg {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background: linear-gradient(145deg, #5CDB95, #79a631);
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  color: white;
}

.SideBar-link {
    display: block;
    color: white;
    line-height: 64px;
    padding: 0 24px;
    text-decoration: none;
    width: 100%;
    margin-left: 20px;
}

a.SideBar-link:hover {
  background-color: white;
  color: red;
}

.link {
  color: darkslategray
}

.link-dark {
  color: white;
}

.link:hover {
  text-decoration: underline;
}

.link-dark:hover{
  text-decoration: underline;
}

.Project-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Divider {
  height: 0.3mm;
  background-color: darkslategray;
}

.Divider-dark {
  height: 0.3mm;
  background-color: rgb(255, 255, 255);
}

.Project-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 20px;
  /* width: fit-content; */
}

.Project-card-description{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 20px;
  /* width: fit-content; */
  font-family: Verdana;
}

.DescriptionText {
  font-size: 11pt;
  line-height: 1.5;
}

#AppsTeam {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  margin-top: 100px;
  margin-bottom: 100px;
}

#AppsTeam button {
  padding: 0;
  border: none;
  background: none;
}

#AppsTeam button img:hover {
  transition: 0.33s;
  z-index: 20;
  transform: scale(1.2);
}

#AppsTeam button #thirdImg {
  position: relative;
  top: -100px;
  opacity: 0;
  animation: 0.33s supermanLanding 0s ease-in forwards;
}

#AppsTeam button #secondImg, #fourthImg {
  position: relative;
  top: -80px;
  opacity: 0;
  animation: 0.66s supermanLanding 0.33s ease-in forwards;
}

#AppsTeam button #firstImg, #fifthImg {
  position: relative;
  top: -40px;
  opacity: 0;
  animation: 1s supermanLanding 0.8s ease-in forwards;
}

@keyframes supermanLanding {
  from {
    opacity: 0;
  }
  to {
    top: -50px;
    opacity: 1;
  }
}


@keyframes showMe {
  from {
    opacity: 0;
    /* height: 0%; */
  }
  to {
    opacity: 1;
    /* height: 100%; */
  }
}

@keyframes hideMe {
  from {
    opacity: 1;
    /* height: 100%; */
  }
  to {
    opacity: 0;
    /* height: 0%; */
  }
}


.showBlock {
  display: block;
   animation: 0.33s showMe 0s ease-in forwards;
}

.hideBlock {
  display: none;
   animation: 0.33s hideMe 0s ease-out forwards;
}

.titleText {
  font-size: 30;
}

.page-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 35vh;
  text-align: center;
  background: linear-gradient(#1ebb65, #1ea903);
}

.page-title h1{
  font-size: 52pt;
  font-weight: bold;
  letter-spacing: -2px;
  line-height: 0.1;
  color: #fff;
}

.page-title h4 {
  position: relative;
  top: -20px;
  font-size: 12pt;
  font-weight: lighter;
  letter-spacing: 1px;
  color: #fff;
}

.page-title-dark {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 35vh;
  text-align: center;
  background: linear-gradient(#131534, #05386b);
}

.page-title-dark h1{
  font-size: 52pt;
  font-weight: bold;
  letter-spacing: -2px;
  line-height: 0.1;
}

.page-title-dark h4 {
  position: relative;
  top: -20px;
  font-size: 12pt;
  font-weight: lighter;
  letter-spacing: 1px;
}

.Project-card-title {  
  margin-bottom: 20px;
  font-weight: bold; 
  display: flex; 
  align-items: center; 
}


.Project-card-image-view {
  display: flex; 
  align-items: center; 
  justify-content: center;
  position: relative;
  top: -100px;
  /* box-shadow: 0px 20px 20px 20px rgb(60, 37, 37); */
}

.Project-card-image {
  margin: 15px;
  width: 200px;
  position: relative;
}
img.Project-card-image {
  object-fit: contain;
}

.Project-card-img-shadow {
  margin-left: 100px;
  width: 300px;
  height: 100px;
  box-shadow: 0px 0px 20px 30px rgba(211, 211, 211, 0.419);
  background-color: rgba(211, 211, 211, 0.419);
  border-radius: 50%;
  /* transform: ; */
}

img.Project-card-image:hover {
  transform: scale(1.5) rotateX(0deg) rotateZ(0deg);
  border-radius: 10px;
  transition: ease-out;
  transition-duration: 0.3s;
  z-index: 10;
}

img.Project-card-image:not(:hover) {
  transition: ease-out;
  transition-duration: 0.3s;
}

div.Overlay {
  background-repeat: no-repeat;
  opacity: 0.5;
  position: absolute;
  left: 0;
  right: 0;
  top: 10;
  bottom: 0;
  width: '100%';
  height: '100%';
  background: #000; 
}

.Negative-Margin {
  margin: -10
}

/* BUTTONS */

.App-button {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  width: fit-content;
  font-size: 12;
  color: #05386b;
  height: 44px;
  text-decoration: none;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
}

.App-button:hover {
  transition: 0.33s ease-in all;
  transform: scale(1.2);
  box-shadow: 0px 3px 5px lightblue;
}

.Non-Decorated-Link {
  text-decoration: none;
}

.White-Title {
  color: white;
}

.App-footer-bg {
  position: relative;
  height: fit-content;
    /* background-image: linear-gradient(rgba(229,60,31), rgb(240, 108, 85)); */
    background-color: #5CDB95;
    color: white;
    left: 0;
    bottom: 0;
    color: white;
    text-align: center;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    padding: 25px;
}


.copy-right{
    position: relative;
    margin-bottom: 0px;
    color: #000;
}

.App-footer {
  display: flex;
  justify-content: space-around;
  background-color: rgb(25,25,25);
  color: white;
}

.App-footer-link-bg {
  margin: 5px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  display: flex;
  font-size: calc(5px + 1vmin);
  font-family: 'highlandLight';
  background-color: rgb(25,25,25);
  color: white;
}


.App-footer-link {
  text-decoration: none;
  padding: 5px;
  background-color: rgb(25,25,25);
  color: white;
}

a.no-decoration-link {
  text-decoration: none;
}

#profile-picture {
  border-radius: 50%;
  object-fit: cover;
  border-width: 5px;
  border-color: white;
  border-style: solid;
  margin: 10px;
}


.Hire-me-text-area{ 
  height: 40vh;
  width: 60%; margin: 30px;
  padding: 20px;
  border-color: white;
  border-radius: 10px;
background: #ffffff;
box-shadow: inset 7px 7px 26px #e0e0e0,
            inset -7px -7px 26px #ffffff;
}

.responsive-resume-content {
  margin-left: 45px;
}

#resume-item-container-header {
  transition: 0.33s transform 0s ease-in-out;
  box-shadow: 0px 0px 15px #05386b;
  /* transform: scale(1.1); */
}

#resume-item-container-header-dark {
  transition: 0.33s transform 0s ease-in-out;
  color: #120746;
  /* transform: scale(1.1); */
}

.responsive-hidden {
  display: none;
}

.responsive-scaled {
  transform: none;
}

.fancy {
  @supports (background-clip: text) or (-webkit-background-clip: text) {
    background-image: 
      url("data:image/svg+xml,%3Csvg width='1700' height='900' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath fill='%2300A080' d='M0 0h2255v899H0z'/%3E%3Ccircle cx='366' cy='207' r='366' fill='%2300FDCF'/%3E%3Ccircle cx='1777.5' cy='318.5' r='477.5' fill='%2300FDCF'/%3E%3Ccircle cx='1215' cy='737' r='366' fill='%23008060'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-size: 100% auto;
    background-position: center;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-30deg);
  }
}

@keyframes menu-bubble-out {
  from {
    transform: scale3d(1, 1, 1);
  }
  to {
    transform: scale3d(1.2, 1.2, 1.2);
  }
}

@keyframes menu-bubble-in {
  from {
    transform: scale3d(1.2, 1.2, 1.2);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}


@keyframes menu-bubble-rotated-out {
  from {
    transform: rotate(0deg) scale3d(1, 1, 1);
  }
  to {
    transform: rotate(-30deg) scale3d(1.2, 1.2, 1.2);
  }
}

@keyframes menu-bubble-rotated-in {
  from {
    transform: rotate(-30deg) scale3d(1.2, 1.2, 1.2);
  }
  to {
    transform: rotate(0deg) scale3d(1, 1, 1);
  }
}

.Responsive-Horizontal-Card-Alignment {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

@media (max-width:320px)  { /* smartphones, iPhone, portrait 480x320 phones */
  .DescriptionText {
    font-size: 10px;
  }
}
@media (max-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ 
  .DescriptionText {
    font-size: 12px;
  }
  #light-icon {
    color: white;
    width: 20px;
    height: 20px;
  }
}
@media (max-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .responsive-scaled {
    transform: scale(0.35);
  }

  .DescriptionText {
    font-size: 14px;
  }

  .Project-Transform {
    transform: none;
  }

  .titleText {
    font-size:medium;
  }

  .Width70Percent {
    width: 100%;
  }

  .responsive-resume-content {
    margin-left: 0px;
  }

  .Responsive-Horizontal-Card-Alignment {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  

  .RatingChip {
    display: flex;
    background-color: transparent;
    border-width: 1px;
    border-style: inset;
    border-color: black;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    justify-items: center;
    margin: 5px;
    font-weight: bold;
  }
  
  .RatingChipFilled {
    background-color: #5CDB95;
  }

  .menu-icon{
    display: block;
    padding: 15px;
    width: 20px;
  }
  .App-header {
    display: none;
  }

  a.SideBar-link:hover {
    transition: ease-out;
    transition-duration: 0.5s;
    padding-left: 60px;
    color: white;
    background-color: red;
  }
  a.SideBar-link:not( :hover ){ transition: ease-in;
    transition-duration: 0.3s;
    padding-left: 50px;
    color: white; 
    background-color: black;
    opacity: 0.5;
  }

  .Project-container {
    display: flex;
    align-items: center;
    margin: 0
  }

  .Project-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px;
    width: fit-content;
  }

  .Compact-Page-Size {
    width: 90%;
  }


  .Project-card-image-view {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px;
    /* object-fit: contain; */
  }

  img.Project-card-image {
    width: 45%;
    object-fit: cover;
  }

  .Hire-me-text-area { height: 40vh; width: 80%; margin-top: 30px; margin-bottom: 30px }

}
@media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .menu-icon{
    display: none !important;
  }

  .SideBar-bg {
    display: none;
  }

  

}
@media (min-width:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */

}
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */
  .responsive-hidden {
    display:inline;
  }
}
@media (min-width:1281px) { /* hi-res laptops and desktops */}
